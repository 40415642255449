import React from "react";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { ScrollToTop } from "@rdcs/dap-front-library";

import PrivateRoute from "./components/Utils/PrivateRoute";

import CalendarScreen from "./screens/Calendar/CalendarScreen";
import DataScreen from "./screens/Data/DataScreen";
import NewPasswordScreen from "./screens/Email/NewPasswordScreen";
import NotFoundExceptionScreen from "./screens/Exceptions/NotFoundExceptionScreen";
import LoginScreen from "./screens/Login/LoginScreen";
import LoginSuccessScreen from "./screens/Login/LoginSuccessScreen";
import LogoutScreen from "./screens/Login/LogoutScreen";
import MedicalDetailsScreen from "./screens/Medical/MedicalDetailsScreen";
import MedicalScreen from "./screens/Medical/MedicalScreen";
import TermsOfUsageScreen from "./screens/Page/TermsOfUsageScreen";
import ExportProfileScreen from "./screens/Profile/ExportProfileScreen";
import ProfileScreen from "./screens/Profile/ProfileScreen";
import DoneRecommandationsScreen from "./screens/Recommandations/DoneRecommandationsScreen";
import RecommandationsScreen from "./screens/Recommandations/RecommandationsScreen";

const App = () => (
    // TODO Find a way to cancel pending API requests when navigating with React Router v6
    <BrowserRouter>
        <ScrollToTop>
            <ToastContainer autoClose={3000} />
            <Routes>
                <Route path="/" element={<Navigate to="/connexion" />} />
                <Route path="/connexion" element={<LoginScreen />} />
                <Route path="/login_success" element={<LoginSuccessScreen />} />
                <Route path="/cgu" element={<TermsOfUsageScreen />} />
                <Route path="/email/new-password" element={<NewPasswordScreen />} />
                <Route element={<PrivateRoute />}>
                    <Route path="/mes-recommandations" element={<RecommandationsScreen />} />
                    <Route path="/mes-recommandations-effectuees" element={<DoneRecommandationsScreen />} />
                    <Route path="/mes-donnees" element={<DataScreen />} />
                    <Route path="/mes-soignants" element={<MedicalScreen />} />
                    <Route path="/mes-soignants/:id/details" element={<MedicalDetailsScreen />} />
                    <Route path="/mon-calendrier" element={<CalendarScreen />} />
                    <Route path="/mon-profil" element={<ProfileScreen />} />
                    <Route path="/mon-profil/export" element={<ExportProfileScreen />} />
                </Route>
                <Route path="/deconnexion" element={<LogoutScreen />} />
                <Route path="*" element={<NotFoundExceptionScreen />} />
            </Routes>
        </ScrollToTop>
    </BrowserRouter>
);

export default App;
