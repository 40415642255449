import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import { tryLogout } from "../../actions/auth";

const LogoutScreen = ({ tryLogout }) => {
    const location = useLocation();
    const [loaded, setLoaded] = React.useState(false);

    React.useEffect(() => {
        tryLogout();
        setLoaded(true);
    }, []);

    return loaded && <Navigate to="/connexion" state={{ from: location }} />;
};

LogoutScreen.propTypes = {
    tryLogout: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    tryLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(LogoutScreen);
