import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { PatientLayout } from "@rdcs/dap-front-library";

const nav = [
    {
        path: "/mes-recommandations",
        icon: "prescription",
        title: "Mes recommandations",
    },
    {
        path: "/mes-donnees",
        icon: "datas",
        title: "Mes données",
    },
    {
        path: "/mes-soignants",
        icon: "pro",
        title: "Mes soignants",
    },
    {
        path: "/mon-calendrier",
        icon: "calendar1",
        title: "Mon calendrier",
    },
];

const userNav = [
    {
        title: "Profil",
        path: "/mon-profil",
        icon: "datas",
    },
    /*
    {
        title: "Mes accompagnants",
        path: "/mes-accompagnants",
        icon: "pro"
    },
    {
        title: "Mes appareils connectés",
        path: "/mes-appareils-connectes",
        icon: "check"
    },*/
    {
        title: "Se déconnecter",
        path: "/deconnexion",
        icon: "close",
    },
];

const AppLayout = ({ breadcrumbs, children, navActive, pageClass, user }) => (
    <PatientLayout
        nav={nav}
        userNav={userNav}
        user={user}
        pageClass={pageClass}
        navActive={navActive}
        breadcrumbs={breadcrumbs}
    >
        {children}
        <footer className="page__footer">
            <hr />
            <Link to="/cgu" className="page__footer--link">
                CGU
            </Link>
        </footer>
    </PatientLayout>
);

AppLayout.propTypes = {
    breadcrumbs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            link: PropTypes.string,
        })
    ),
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
    navActive: PropTypes.number,
    pageClass: PropTypes.string,
    user: PropTypes.object.isRequired,
};

const mapStateToProps = ({ auth: { user } }) => ({ user });

export default connect(mapStateToProps)(AppLayout);
