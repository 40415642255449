import React from "react";

import PropTypes from "prop-types";
import * as queryString from "query-string";
import { Animated } from "react-animated-css";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import {
    DefaultLoader,
    downloadFileLink,
    getPrivateFileUrl,
    getViolations,
    HorizontalNavbarTab,
    PageTitle,
    useLoadAfterPromises,
} from "@rdcs/dap-front-library";

import navBar from "./navBar";

import { tryFetchProfile, tryEditProfile } from "../../actions/profile";
import AppLayout from "../../components/Layout/AppLayout";
import ProfileForm from "../../components/Profile/ProfileForm";

const ProfileScreen = ({ profile, profileEditError, profileEditLoading, tryEditProfile, tryFetchProfile }) => {
    const location = useLocation();

    const urlParams = React.useMemo(() => queryString.parse(location.search), [location.search]);

    const loadingRequestsAPI = React.useMemo(() => [tryFetchProfile], []);
    const loading = useLoadAfterPromises(loadingRequestsAPI);

    React.useEffect(() => {
        if (urlParams.downloadPersonalData) {
            toast.info("Vos données personnelles sont en cours de téléchargement", { autoClose: 5000 });
            getPrivateFileUrl(urlParams.downloadPersonalData)
                .then((file) => {
                    downloadFileLink(file.url, "Personal_data.zip");
                    toast.success("Vos données personnelles ont bien été téléchargées.", { autoClose: 5000 });
                })
                .catch(() => toast.error("Une erreur s'est produite", { autoClose: 5000 }));
        }
    }, []);

    return (
        <PageTitle title="Timkl - Profil">
            <AppLayout navActive={null}>
                <HorizontalNavbarTab navbar={navBar} tabActive={0} />
                <div>
                    {loading ? (
                        <DefaultLoader color="#CCC" />
                    ) : (
                        <Animated animationIn="fadeIn">
                            <ProfileForm
                                submit={tryEditProfile}
                                profile={profile}
                                profileEditLoading={profileEditLoading}
                                violations={profileEditError}
                            />
                        </Animated>
                    )}
                </div>
            </AppLayout>
        </PageTitle>
    );
};

ProfileScreen.propTypes = {
    profile: PropTypes.object,
    profileEditError: PropTypes.object.isRequired,
    profileEditLoading: PropTypes.bool.isRequired,
    tryEditProfile: PropTypes.func.isRequired,
    tryFetchProfile: PropTypes.func.isRequired,
};

const mapStateToProps = ({ profile: { profile, profileEditError, profileEditLoading } }) => ({
    profile,
    profileEditError: getViolations(profileEditError),
    profileEditLoading,
});

const mapDispatchToProps = {
    tryEditProfile,
    tryFetchProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileScreen);
